// Modules / Helpers / Store
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import ProtectedRoutes from './utils/ProtectedRoutes';
import { AuthProvider } from './utils/AuthContext';
import { store } from './store';
import theme from './theme';

// Components
import AccountPage from './pages/Auth/Account';
import AlertPopup from './components/Elements/Alert';
import HomePage from './pages/Home';
import LoginPage from './pages/Auth/Login';
import PasswordForgottenPage from './pages/Auth/PasswordForgotten';
import RegisterPage from './pages/Auth/Register';
import ResetPasswordPage from './pages/Auth/ResetPassword';
import StatsPage from './pages/Stats';
import WrappedPage from './pages/Wrapped';

// Styles
import GlobalStyles from './globalStyles';

const App = () => {
  const refreshCache = () => {
    if (caches) {
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const analyticsScript = document.createElement('script');
      analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_ANALYTICS_ID}`;
      analyticsScript.async = true;
      document.querySelector('head')?.prepend(analyticsScript);
      axios.get('/version.json').then((response) => {
        if (response.data) {
          const storedVersion = localStorage.getItem('appVersion');
          if (!storedVersion) {
            localStorage.setItem('appVersion', response.data);
          } else if (storedVersion && response.data !== storedVersion) {
            refreshCache();
          }
        }
      });
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlertPopup />
        <AuthProvider>
          <Router>
            <GlobalStyles />
            <Provider store={store}>
              <Routes>
                <Route element={<ProtectedRoutes />}>
                  <Route element={<HomePage />} path="/" />
                  <Route element={<StatsPage />} path="/stats" />
                  <Route element={<WrappedPage />} path="/wrapped" />
                  <Route element={<AccountPage />} path="/account" />
                </Route>
                <Route element={<LoginPage />} path="/login" />
                <Route element={<RegisterPage />} path="/register" />
                <Route element={<PasswordForgottenPage />} path="/password-forgotten" />
                <Route element={<ResetPasswordPage />} path="/reset-password" />
              </Routes>
            </Provider>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
