// Libraries
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../store';
import uniq from 'lodash/uniq';
import { Theme } from '../../types';
import { months } from '../../constants';

// Components
import { ShowIcon } from '../../components/Elements/Icons';
import AppPageLayout from '../../components/Layout/AppPage';

// Styles
import {
  PageContainer,
  PageTitle,
  IconContainer,
  ListAchievements,
  Highlight,
  Separator,
  ListItems,
  Item,
  TopPosition,
  MonthName,
  ListCities,
  Coma,
  FullSection,
  HalfSection,
} from './styles';

const WrappedPage = () => {
  const theme = useTheme() as Theme;
  const { userShows } = useAppSelector(
    (state) => ({
      userShows: state.api.userShows,
    }),
    shallowEqual,
  );

  const wrappedYear = new Date().getFullYear() - 1;

  const currentYearShows = useMemo(
    () => userShows?.filter((show) => new Date(show.date).getFullYear() === wrappedYear),
    [userShows],
  );

  const previousYearShows = useMemo(
    () => userShows?.filter((show) => new Date(show.date).getFullYear() === wrappedYear - 1),
    [userShows],
  );

  const pastShows = useMemo(
    () => userShows?.filter((show) => new Date(show.date).getFullYear() < wrappedYear),
    [userShows],
  );

  const artistsUniquePast: number[] = [];

  pastShows?.forEach((show) => {
    const { artists } = show;
    artists.forEach((artist) => {
      const { id } = artist;
      if (id && !artistsUniquePast.includes(id)) {
        artistsUniquePast.push(id);
      }
    });
  });

  const topVenues: {
    id: number;
    name: string;
    city: string;
    count: number;
  }[] = [];
  const artistsSaw: {
    id: number;
    name: string;
    count: number;
  }[] = [];
  const newArtistsSaw: {
    id: number;
    name: string;
  }[] = [];
  currentYearShows?.forEach((show) => {
    const { artists, venue } = show;
    // Venues
    const indexVenue = topVenues.findIndex((topVenue) => topVenue.id === venue.id);
    if (indexVenue > -1) {
      topVenues[indexVenue].count += 1;
    } else {
      topVenues.push({
        id: venue.id,
        name: venue.name,
        city: `${venue.cityName} (${venue.countryCode})`,
        count: 1,
      });
    }
    // Artists
    artists.forEach((artist) => {
      const indexArtist = artistsSaw.findIndex((artistSaw) => artistSaw.id === artist.id);
      if (indexArtist > -1) {
        artistsSaw[indexArtist].count += 1;
      } else {
        artistsSaw.push({
          id: artist.id as number,
          name: artist.name as string,
          count: 1,
        });
      }
      if (!artistsUniquePast.includes(artist.id as number)) {
        newArtistsSaw.push({
          id: artist.id as number,
          name: artist.name as string,
        });
      }
    });
  });

  topVenues.sort((a, b) => b.count - a.count);
  artistsSaw.sort((a, b) => b.count - a.count);

  const artistsSawMultipleTimes = artistsSaw.filter((artist) => artist.count > 1);
  const differenceShowsFromLastYear =
    currentYearShows?.length && previousYearShows?.length ? currentYearShows.length - previousYearShows.length : 0;

  const listCities = uniq(currentYearShows?.map((show) => show.venue.cityName));

  const listArtistsPerMonth: { [key: number]: string[] } = {};
  currentYearShows?.forEach((show) => {
    const month = new Date(show.date).getMonth();
    if (!listArtistsPerMonth[month]) {
      listArtistsPerMonth[month] = [];
    }
    if (show.artists.length) {
      show.artists.map((artist) => {
        if (artist.name && !listArtistsPerMonth[month].includes(artist.name)) {
          listArtistsPerMonth[month].push(artist.name);
        }
      });
    }
  });

  type IListItems = { items: string[]; type: string };
  const listItems = ({ items, type }: IListItems) => {
    return (
      <>
        {items.length > 1 && (
          <>
            {items.slice(0, -1).map((item, index) => {
              const suffix = index === items.length - 2 ? '' : <Coma>, </Coma>;
              return (
                <>
                  <Highlight key={`${type}_${index}`}>{item}</Highlight>
                  {suffix}
                </>
              );
            })}
            and
          </>
        )}
        {items.slice(-1).map((item, index) => (
          <Highlight key={`${type}_${index}`}>{item}</Highlight>
        ))}
      </>
    );
  };

  return (
    <AppPageLayout showMenu={false}>
      <PageContainer>
        <PageTitle>Wrapped {wrappedYear}</PageTitle>
        {currentYearShows?.length ? (
          <ListAchievements>
            <HalfSection>
              You went to <Highlight isNumber={true}>{currentYearShows.length}</Highlight> shows
            </HalfSection>
            {differenceShowsFromLastYear !== 0 && (
              <HalfSection>
                That's <Highlight isNumber={true}>{Math.abs(differenceShowsFromLastYear)}</Highlight>{' '}
                {differenceShowsFromLastYear > 0 ? 'more' : 'less'} than last year
              </HalfSection>
            )}
            <Separator />
            <HalfSection>
              You saw <Highlight isNumber={true}>{artistsSaw.length}</Highlight> different artists
            </HalfSection>
            {newArtistsSaw.length > 0 && (
              <HalfSection>
                including <Highlight isNumber={true}>{newArtistsSaw.length}</Highlight> for the first time
              </HalfSection>
            )}
            {artistsSawMultipleTimes.length && (
              <>
                <Separator />
                <FullSection>
                  You even saw{' '}
                  {listItems({
                    items: artistsSawMultipleTimes.map((artist) => artist.name),
                    type: 'wrapped_artist',
                  })}{' '}
                  more than once.
                </FullSection>
                <FullSection>You must be a real fan!</FullSection>
              </>
            )}
            {topVenues.length && (
              <>
                <Separator />
                {listCities.length > 0 && (
                  <ListCities>
                    You went to{' '}
                    {listItems({
                      items: listCities,
                      type: 'wrapped_cities',
                    })}
                  </ListCities>
                )}
                <FullSection>Most visited venues:</FullSection>
                <FullSection>
                  <ListItems>
                    {topVenues.slice(0, 3).map((venue, index) => (
                      <Item isNumber key={venue.name}>
                        <TopPosition>#{index + 1}</TopPosition>
                        {venue.name}
                        <Highlight isNumber={true}>{venue.count}</Highlight>
                      </Item>
                    ))}
                  </ListItems>
                </FullSection>
              </>
            )}
            <Separator />
            {Object.entries(listArtistsPerMonth).length > 0 && (
              <>
                <FullSection>Artists per month:</FullSection>
                <FullSection>
                  <ListItems>
                    {Object.keys(listArtistsPerMonth).map((key: string) => {
                      const value = listArtistsPerMonth[parseInt(key)];
                      return (
                        value && (
                          <Item key={`wrapped_month_${key}`}>
                            <MonthName>
                              <Highlight>{months[parseInt(key)]}</Highlight>
                            </MonthName>
                            {value.map((artistName) => (
                              <li>{artistName}</li>
                            ))}
                          </Item>
                        )
                      );
                    })}
                  </ListItems>
                </FullSection>
              </>
            )}
            <Separator />
            <FullSection>What a year!</FullSection>
            <FullSection>
              <Highlight noBorder>See you in 2025!</Highlight>
            </FullSection>
          </ListAchievements>
        ) : (
          <>
            <IconContainer>
              <ShowIcon color={theme.colors['Casper']} />
            </IconContainer>
            <p>No data for this year! :(</p>
          </>
        )}
      </PageContainer>
    </AppPageLayout>
  );
};

export default WrappedPage;
