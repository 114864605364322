import styled, { css } from 'styled-components';
import { min } from '../../theme/breakpoints';

export const PageContainer = styled.div`
  margin: 3.2rem 0;
`;

export const PageTitle = styled.h2`
  font-size: 4rem;
  color: ${({ theme }) => theme.colors['GoldTips']};
  margin-bottom: 3.2rem;

  ${min('tablet')} {
    margin-bottom: 6.4rem;
    font-size: 8rem;
  }
`;

export const IconContainer = styled.div`
  width: 20rem;
  margin: 3.2rem auto 0;
`;

export const ListAchievements = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  > li {
    font-family: ${({ theme }) => theme.fonts.robotoCondensed};
    text-transform: uppercase;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${min('tablet')} {
      flex-direction: row;
      font-size: 3rem;
    }
  }
`;

type IsNumber = {
  isNumber?: boolean;
}

type NoBorder = {
  noBorder?: boolean;
}

export const Highlight = styled.span<IsNumber & NoBorder>`
  text-transform: none;
  font-size: ${({ isNumber }) => isNumber ? '5rem' : '2.4rem'};
  font-family: ${({ theme }) => theme.fonts.libreBaskerville};
  color: ${({ theme }) => theme.colors['GoldTips']};
  box-shadow: ${({ noBorder, theme }) => noBorder ? 'none' : `0 0 1px ${theme.colors['GoldTips']}`};
  padding: ${({ noBorder }) => noBorder ? '0' : '1.6rem'};
  border-radius: 1.6rem;
  margin: 1.6rem;
  min-width: 10rem;

  ${min('tablet')} {
    font-size: ${({ isNumber }) => isNumber ? '5rem' : '4rem'};
    min-width: auto;
    margin: 0 0.5rem;
    box-shadow: none;
    padding: 0;
  }
`;

export const Separator = styled.li`
  height: 1px;
  flex: 0 0 100%;
  width: 100rem;
  max-width: 100%;
  background: ${({ theme }) => theme.colors['GoldTips']}; 
  background: linear-gradient(90deg, rgba(17,17,18,1) 0%, rgba(242,203,64,1) 50%, rgba(17,17,18,1) 100%);
  margin: 1.6rem auto;
  opacity: 0.25;

  ${min('tablet')} {
    margin: 3.2rem auto;
  }
`;

export const Item = styled.li<IsNumber>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px ${({ theme }) => theme.colors['GoldTips']};
  border-radius: 1.6rem;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  text-transform: uppercase;
  position: relative;
  max-width: ${({ isNumber }) => isNumber ? '20rem' : '100%'};

  ${({ isNumber }) => isNumber ? css`
    padding: 3.2rem 1.6rem;
    &:nth-child(1) {
      flex: 0 0 100%;
    }
    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 0 calc(50% - 0.8rem);
    }
  ` : css`
    padding: 1.6rem;
    justify-content: flex-start;
    flex: 0 0 calc(50% - 0.8rem);
  `};
  ${min('tablet')} {
    font-size: 2rem;
    flex: 1;

    ${({ isNumber }) => !isNumber && css`
      flex: 0 0 calc(33.333% - 3.2rem);
    `};
  }
  li {
    font-family: ${({ theme }) => theme.fonts.robotoCondensed};
  }
`;

export const ListItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 2rem !important;
  width: 100%;
  max-width: 100rem;

  ${min('tablet')} {
    gap: 3.2rem;
  }
  ${Highlight} {
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
`;

export const TopPosition = styled.span`
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  color: ${({ theme }) => theme.colors['GoldTips']};
  font-weight: bold;
`;

export const ListMonths = styled.ul`
  width: 100%;

  ${Number} {
    max-width: auto;
  }
`;

export const ListCities = styled.li`
  margin-bottom: 1.6rem;
  flex: 0 0 100%;
`;

export const Month = styled.li`
  flex: 1;
`;

export const MonthName = styled.li`
  margin-bottom: 1.6rem;
  ${Highlight} {
    font-size: 2rem;
  }

  ${min('tablet')} {
    ${Highlight} {
      font-size: 3.2rem;
    }
  }
`;

export const Coma = styled.span`
  display: none;

  ${min('tablet')} {
    display: inline-block;
  }
`;

export const FullSection = styled.li`
  flex: 0 0 100%;
`;

export const HalfSection = styled.li`
  flex: 0 0 50%;

  ${min('tablet')} {
    flex: 0 0 100%;
  }
`;
